<div class="  content">
    <app-banner [pageType]="'academy'" [BannerImage]="'hero_front_page_background.png'"
        [pageloading]="pageloading"></app-banner>
    <section class="academy" id="about-bjsacademy">
        <div class="text-section">
            <div class="row  about-section">
                <div class="text-contentaward col-xl-6 col-12 pe-3">
                    <div class="pt-3 pe-5 me-5">
                        <h1>About BJS Academy </h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                It is essential for surgeons to engage in life-long learning to provide patients with
                                the
                                best available treatments.
                            </p>
                            <p>
                                Divided into five sections, BJS Academy is an online education resource that supports
                                the
                                professional development of
                                current and future surgeons worldwide by championing research and collaboration.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="image-contentaward col-xl-6 col-12 d-flex justify-content-end">
                    <img src="home-academy.png" alt="">
                </div>
            </div>
        </div>

    </section>
    <section class="about-surgical" id="continuing-surgical">
        <div class="continuing-surgical-section">
            <div class="row  about-section text-section background1">
                <div class="text-contentaward col-xl-5 col-12">
                    <div class="pe-5">
                        <h1>Continuing surgical education </h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                Committing to a career in surgery means keeping abreast of medical and surgical progress
                                throughout 20–30 years.
                            </p>
                            <p class="pt-2">
                                Continuing surgical education summarises advances in various subspecialties, as well as
                                providing lectures and vodcasts on topics of interest. If you wish to pursue formal
                                credentialling or educational training courses,
                                you can find out more about the <a class="red-link"
                                    [routerLink]="'/page/bjs-institute'"> BJS Institute’s</a> collaboration with the
                                University
                                of
                                Edinburgh.
                            </p>
                        </div>
                        <p class="pt-2">
                            <a [routerLink]="'continuing-surgical-education/page/1'">
                                <button class="btn btn-blue btn-arrow ">
                                    Explore Continuing surgical education
                                    <span class="ps-3"><fa-icon [icon]="['fas','angle-right']"></fa-icon></span>
                                </button>
                            </a>
                        </p>
                    </div>

                </div>
                <div class="image-contentaward col-xl-7 col-12">
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="cseBjs?.length > 0"
                                class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
                                <app-article-card-list *ngFor="let article of cseBjs; let i = index" [article]="article"
                                    class="article-listing-card"></app-article-card-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="academy about-youngbjs" id="young-bjs">
        <div class="text-section">
            <div class="row  about-section background2">
                <div class="text-contentaward col-xl-5 col-12">
                    <div class="pe-5">
                        <h1>Young BJS </h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                Dedicated to and managed by surgeons in training, Young BJS offers everything a trainee
                                could need to supplement and expand upon their core learning.
                            </p>
                            <p class="pt-2">
                                Focusing on the importance of surgical research, it gives trainees the opportunity to
                                read
                                and critique research, design surgical projects and optimise their chances of being
                                published in peer-reviewed <a class="red-link" style="color: #ffffff;"
                                    [routerLink]="'/page/bjs-journals'">journals</a>.
                            </p>
                        </div>
                        <p class="pt-2">
                            <a [routerLink]="'young-bjs/page/1'">
                                <button class="btn btn-white btn-arrow ">
                                    Discover Young BJS
                                    <span class="ps-3"><fa-icon [icon]="['fas','angle-right']"></fa-icon></span>
                                </button>
                            </a>
                        </p>
                    </div>

                </div>
                <div class="image-contentaward col-xl-7 col-12">
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="youngBjs?.length > 0"
                                class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
                                <app-article-card-list *ngFor="let article of youngBjs; let i = index"
                                    [article]="article" class="article-listing-card"></app-article-card-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <section class="about-surgical" id="cutting-edge">
        <div class="continuing-surgical-section">
            <div class="row  about-section text-section background1">
                <div class="text-contentaward col-xl-5 col-12">
                    <div class="pe-5">
                        <h1>Cutting edge blog</h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                Globally recognised journals, BJS and BJS Open, deliver a wealth of quality materials
                                surrounding surgical science and learning.
                            </p>
                            <p class="pt-2">
                                The Cutting edge blog offers commentary and opinion pieces about published papers,
                                journal clubs and debates for regular readers of the Foundation’s journals, as well as
                                summary digests of other recent surgical publications.
                            </p>
                        </div>
                        <p class="pt-2">
                            <a [routerLink]="'cutting-edge-blog/page/1'">
                                <button class="btn btn-blue btn-arrow ">
                                    Read Cutting edge articles
                                    <span class="ps-3"><fa-icon [icon]="['fas','angle-right']"></fa-icon></span>
                                </button>
                            </a>
                        </p>
                    </div>

                </div>
                <div class="image-contentaward col-xl-7 col-12">
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="cebBjs?.length > 0"
                                class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
                                <app-article-card-list *ngFor="let article of cebBjs; let i = index" [article]="article"
                                    class="article-listing-card"></app-article-card-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="academy about-youngbjs" id="scentific-surgery">
        <div class="text-section">
            <div class="row  about-section background2">
                <div class="text-contentaward col-xl-5 col-12">
                    <div class="pe-5">
                        <h1>Scientific surgery
                        </h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                Designed to help the busy surgeon keep up to date, Scientific surgery provides succinct
                                summaries of new and interesting information collated from leading surgical journals and
                                digital media.
                            </p>
                        </div>
                    </div>

                </div>
                <div class="image-contentaward col-xl-7 col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="scientific-surgery-links">
                                <a [routerLink]="'scientific-surgery/randomized-clinical-trials/page/1'">
                                    <span>Randomized clinical trials</span>
                                    <hr class="hr hr--color hr--sm">
                                    For all clinical surgeons
                                    <p class="button-explore"><button class="btn-small btn-blue btn-arrow ">
                                            Explore more
                                            <span class="ps-3"><fa-icon style="color: #ffffff;"
                                                    [icon]="['fas','angle-right']"></fa-icon></span>
                                        </button></p>
                                </a>
                                <a [routerLink]="'scientific-surgery/surgical-science/page/1'">
                                    <span>Surgical science</span>
                                    <hr class="hr hr--color hr--sm">
                                    For those interested in the science behind surgery
                                    <p class="button-explore"><button class="btn-small btn-blue btn-arrow ">
                                            Explore more
                                            <span class="ps-3"><fa-icon style="color: #ffffff;"
                                                    [icon]="['fas','angle-right']"></fa-icon></span>
                                        </button></p>
                                </a>
                                <a [routerLink]="'scientific-surgery/social-media/page/1'">
                                    <span>Social media</span>
                                    <hr class="hr hr--color hr--sm">
                                    A monthly digest of the best digital material
                                    <p class="button-explore"><button class="btn-small btn-blue btn-arrow ">
                                            Explore more
                                            <span class="ps-3"><fa-icon style="color: #ffffff;"
                                                    [icon]="['fas','angle-right']"></fa-icon></span>
                                        </button></p>
                                </a>
                                <a [routerLink]="'scientific-surgery/surgery-for-all/page/1'">
                                    <span>Surgery for all</span>
                                    <hr class="hr hr--color hr--sm">
                                    For non-surgeons
                                    <p class="button-explore"><button class="btn-small btn-blue btn-arrow ">
                                            Explore more
                                            <span class="ps-3"><fa-icon style="color: #ffffff;"
                                                    [icon]="['fas','angle-right']"></fa-icon></span>
                                        </button></p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <section class="about-surgical" id="surgical-new">
        <div class="continuing-surgical-section">
            <div class="row  about-section text-section background1">
                <div class="text-contentaward col-xl-5 col-12">
                    <div class="pe-5">
                        <h1>Surgical news </h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                Exploring topics relevant to both surgeons and people without medical training, Surgical
                                news is accessible to everyone.
                            </p>
                            <p class="pt-2">
                                Many patients seek reliable information on the best treatments for surgical diseases,
                                whilst
                                others have a general interest in reading quality articles about surgeons and surgical
                                practice.
                            </p>
                        </div>
                        <p class="pt-2">
                            <a [routerLink]="'surgical-news/page/1'">
                                <button class="btn btn-blue btn-arrow ">
                                    Read Surgical news
                                    <span class="ps-3"><fa-icon [icon]="['fas','angle-right']"></fa-icon></span>
                                </button>
                            </a>
                        </p>
                    </div>

                </div>
                <div class="image-contentaward col-xl-7 col-12">
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="snBjs?.length > 0"
                                class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
                                <app-article-card-list *ngFor="let article of snBjs; let i = index" [article]="article"
                                    class="article-listing-card"></app-article-card-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="academy" id="academy-team">
        <div class="text-section">
            <div class="row  about-section">
                <div class="text-contentaward col-12">
                    <div class="pt-3">
                        <h1>BJS Academy team </h1>
                        <hr class="hr hr--color hr--lg">
                        <div class="timeline-desc">
                            <p class="lead">
                                The talented team behind the Academy bring a wealth of knowledge and experience to their
                                roles.
                            </p>
                            <p>
                                The Academy website is managed by IT partners, <a href="https://rivervalley.io"
                                    class="red-link" target="_blank" rel="noopener">River Valley Technologies</a>, a
                                Content
                                Management
                                team coordinated by <a href="https://www.acsglobal.co" class="red-link" target="_blank"
                                    rel="noopener">ACS Global</a> and a BJS Academy Board managed by Jonothan Earnshaw.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row  about-section">
                <div class="text-contentaward col-12">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
                            <div class="card">
                                <div class="card__img">
                                    <div class="img">
                                        <img width="1500" height="1500" src="Jonothan-Madrid-v3.jpg"
                                            class=" ls-is-cached lazyloaded" alt="Director"
                                            data-src="Jonothan-Madrid-v3.jpg" decoding="async" loading="lazy"
                                            sizes="(max-width: 1500px) 100vw, 1500px">
                                    </div>
                                </div>
                                <div class="card__info">
                                    <p class="title">Director</p>
                                    <hr class="hr hr--color hr--sm">
                                    <p>Jonothan Earnshaw manages and coordinates the activities of BJS Academy.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
                            <div class="card">
                                <div class="card__img">
                                    <div class="img">
                                        <img width="1500" height="1500" src="iStock-1044232206-2048x1365.jpg"
                                            class=" ls-is-cached lazyloaded" alt="Director"
                                            data-src="iStock-1044232206-2048x1365.jpg" decoding="async" loading="lazy"
                                            sizes="(max-width: 1500px) 100vw, 1500px">
                                    </div>
                                </div>
                                <div class="card__info">
                                    <p class="title">BJS Academy Board</p>
                                    <hr class="hr hr--color hr--sm">
                                    <p>Supporting the Director, this team creates the Academy’s educational resources.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
                            <div class="card">
                                <div class="card__img">
                                    <div class="img">
                                        <img width="1500" height="1500"
                                            src="0170Caireen-Harvey-Photography-2048x1609.jpg"
                                            class=" ls-is-cached lazyloaded" alt="Director"
                                            data-src="0170Caireen-Harvey-Photography-2048x1609.jpg" decoding="async"
                                            loading="lazy" sizes="(max-width: 1500px) 100vw, 1500px">
                                    </div>
                                </div>
                                <div class="card__info">
                                    <p class="title">Management team</p>
                                    <hr class="hr hr--color hr--sm">
                                    <p>This team supports the Director to deliver the strategy and manage the delivery
                                        of the Academy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </section>
</div>