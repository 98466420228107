<div class="article-card" *ngIf="!pageloading;else PageNotLoaded">
    <div class="article-info">
        <span *ngIf="article?.rct_of_the_month" class="rct-label" title="Commentary">
            RCT of the Month</span>
        <a class="heading_link" [routerLink]="['/bjs-academy',article?.category_heading?.id,article?.article_link]"><h2 class="article-title" title="{{title}}"
            [innerHTML]="title"></h2></a>
        <p *ngIf="article?.authors && article?.authors.length > 0">
            <!-- Use helper methods to check the type of authors array -->
            <span *ngIf="isStringArray(article?.authors)" [innerHtml]="article?.authors.join(', ') ">
            </span>
            <span *ngIf="isObjectArray(article?.authors)">
                <span *ngFor="let author of article?.authors; let isLast = last" [innerHtml]=" author?.name ">
                <span *ngIf="!isLast">, </span>
                </span>
            </span>
        </p>
        <p *ngIf="article?.abstract" [innerHtml]="html_new_content_dupe"></p>
        <p *ngIf="article?.published_online" class="article-date">{{ article?.published_online }}</p>
        <div class="article-tags">
            <a *ngIf="returnCategory(article?.category_heading.id)" class="tag-content">
                <span [innerHTML]="highlightReferenceHeading(article?.category_heading.title)" (click)="categoryClick(article?.category_heading.id, article?.category_heading.title)">
                </span>
            </a>
            <a *ngFor="let tag of article?.surgical_speciality" class="tag-content">
                <span [innerHTML]="highlightReference(tag)" (click)="filterClicked(tag, 'speciality', article.category_heading.id);highlightReference(tag)"></span>
            </a>
            <a *ngFor="let type of article?.content_type" class="tag-content">
                <span [innerHTML]="highlightType(type)" (click)="filterClicked(type, 'type');highlightType(type)"></span>
            </a>
        </div>
        <div class="row">
            <div class="col-10 rct-article-abstract">
                <a class="download" (click)="navigateToArticle(article?.article_link,article?.category_heading.id)">
                    <span>View individual abstract</span>
                </a>
                <a class="download" href="{{article?.read_paper}}" target="_blank">
                    <span>Read paper</span>
                </a>
            </div>
            <div class="col-2">
                <div class="share-button d-flex justify-content-end">
                    <div class="dropdown">
                        <span class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <fa-icon [icon]="['fas', 'share-nodes']" class="share-icon"></fa-icon>
                        </span>
                        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <span class="dropdown-item p-2" href="#">
                                    <share-button button="facebook" text="Share" showText
                                        [url]="sharedUrl+'/academy/'+article?.category_heading+'/'+article?.article_link"></share-button>
                                </span>
                            </li>

                            <li>
                                <span class="dropdown-item p-2" href="#">
                                    <share-button button="x" text="Twitter" showText
                                        [url]="sharedUrl+'/academy/'+article?.category_heading+'/'+article?.article_link"></share-button>
                                </span>
                            </li>

                            <li>
                                <span class="dropdown-item p-2" tabindex="0" title="Linkedin">
                                    <share-button button="linkedin" text="Linkedin" showText
                                        [url]="sharedUrl+'/academy/'+article?.category_heading+'/'+article?.article_link"></share-button>
                                </span>
                            </li>
                            <li>
                                <span class="dropdown-item p-2" tabindex="0" title="Email">
                                    <share-button button="email" text="Email" showText
                                        [url]="sharedUrl+'/academy/'+article?.category_heading+'/'+article?.article_link"></share-button>
                                </span>
                            </li>
                            <li><span class="dropdown-item p-2">
                                    <share-button button="copy" text="Copy Link" showText
                                        [url]="sharedUrl+'/academy/'+article?.category_heading+'/'+article?.article_link"></share-button>
                                </span>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #PageNotLoaded>
    <div class="article-card">
        <div class="article-info">
            <span class=" skeleton skeleton-text skeleton-text-80 "></span>
            <h2  class="skeleton-card-title skeleton skeleton-text "></h2>
            <p class="skeleton-author skeleton skeleton-text skeleton-text-80"></p>

            <div>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class="skeleton-author skeleton skeleton-text skeleton-text-80"></p>
            </div>
            
            <p class="skeleton-author skeleton skeleton-text skeleton-text-80 article-date"></p>
            
            <div class="article-tags">
                    <div class="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-10 ">
                        <div class="badges ml-2 overflow ">
                            <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    
                            </div>
                            &nbsp; 
                            <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            </div>
    
                        </div>
    
                    </div>
            </div>
            <div class="row">
                <div class="col-10 rct-article-abstract">
                    <a class="download skeleton skeleton-text">
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </a>
                    <a class="download skeleton skeleton-text">
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </a>
                </div>
                <div class="col-2">
                    <div class="share-button d-flex justify-content-end">
                        <div class="dropdown">
                            <span type="button" class="skeleton skeleton-text">
                                &nbsp; &nbsp; &nbsp; &nbsp;
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>